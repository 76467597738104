<template>
    <div>
        <video
            id="videojs-player"
            style="width: 100%; height: 100%; position: absolute;"
            class="video-js vjs-default-skin vjs-live"
            controls
            playsinline></video>
    </div>
</template>
<script>
    import videojs from "video.js";
    import "video.js/dist/video-js.css";

    export default {
        data(){
            return {
                player: null,
            }
        },
        mounted() {
            console.log(this.$route);
            this.player = videojs("videojs-player", {
                html5: {
                    hls: {
                        overrideNative: true
                    },
                    nativeVideoTracks: true,
                    nativeAudioTracks: true,
                    nativeTextTracks: true
                },
                controls: true,
                liveui: false,
                autoplay: true,
                suppressNotSupportedError: true,
                notSupportedMessage: 'No live stream available',
                sources: [
                    {
                        type: "application/x-mpegURL",
                        src: this.$route.query['mUrl']
                    }
                ]
            }, () => {
                console.log('onplayerReady', this);
            });

        },
        beforeUnmount() {
            if(this.player){
                this.player.dispose();
            }
        }
    }
</script>
<style>

</style>